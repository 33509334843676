import React, { useState } from "react";
const Home = () => {
  return (
    <div className="App">
      <header>
        <div>
          <p>Site en construction</p>
        </div>
      </header>
      <main>{/* Your content goes here */}</main>
    </div>
  );
};
export default Home;
